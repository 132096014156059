<template>
  <b-modal
    ref="refund-modal"
    id="refund-modal"
    class="refund-modal"
    ok-title="Confirm"
    size="w-refund"
    hide-footer
    hide-header
    hide-header-close
    centered
    no-close-on-backdrop
  >
    <!--Content-->
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary">
        {{ showSuccessView ? '' : $t('SMB.payment.titleRequestWithdrawal') }}
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <div v-if="!showSuccessView">
      <validation-observer
        ref="formValidation"
      >
        <b-form>
          <b-row>
            <b-col
              sm="12"
              md="12"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('SMB.payment.labelAmountWithdrawal') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  rules="required|rangeRefundLocalBank"
                  :name="$t('SMB.payment.amount')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.number="amount"
                      name="amount"
                      type="number"
                      class="input-height"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="mt-1 font-italic d-flex align-items-start">
                  <p class="mb-0">
                    {{ $t('SMB.payment.exchangedMoney') }}
                  </p>
                  <feather-icon
                    v-b-tooltip.v-light="`~ 1 USD = ${localStringFormat(USDToVNDRate)} VNĐ`"
                    icon="HelpCircleIcon"
                    size="12"
                    class="icon-info"
                  />: {{ localStringFormat(convertUSDtoVND(amount)) }} VNĐ
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
                :name="$t('SMB.payment.bank')"
              >
                <b-form-group :state="errors.length > 0 ? false : null">
                  <div>
                    <div class="d-flex justify-content-between">
                      <label class="form-label">
                        {{ $t('SMB.payment.selectBankName') }}
                      </label>
                    </div>
                    <v-select
                      v-model="bankType"
                      class="list-bank-select-refund"
                      label="name"
                      :options="localBanks"
                      :clearable="false"
                      :filter-by="handleSearchLocalBanks"
                      @input="handleChangeBank"
                    >
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                          />
                        </span>
                      </template>

                      <template #selected-option="{ logo, name, shortName, code }">
                        <img
                          class="bank-logo"
                          alt="img"
                          :src="logo"
                        >
                        <strong class="coin-spacing">{{ shortName }}, {{ code }}</strong>
                      </template>

                      <template #option="{ logo, name, shortName, code }">
                        <div class="d-flex align-items-center">
                          <img
                            class="bank-logo"
                            alt="img"
                            :src="logo"
                          >
                          <div>
                            <p class="mb-0 font-14">
                              {{ shortName }}, {{ code }}
                            </p>
                            <span class="font-14">{{ name }}</span>
                          </div>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    {{ $t('SMB.payment.labelRecipientName') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('SMB.payment.labelRecipientName')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model="bankAccountName"
                      name="account name"
                      class="input-height"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label
                    class="form-label"
                    for="Bank Account Number"
                  >
                    {{ $t('SMB.payment.labelAccountNumber') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('SMB.payment.labelAccountNumber')"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="bankAccountNumber"
                      name="bankAccountNumber"
                      class="input-height"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <div
      v-else
      class="d-flex flex-column align-items-center"
    >
      <b-img
        rounded
        class="mr-50"
        :src="require('@/assets/images/pages/adsAccount/icon-success.png')"
        style="width: 60px"
      />
      <h4 class="mt-1">
        {{ $t('SMB.payment.textRequestWithdrawal') }}
      </h4>
      <p class="mt-1"> {{ $t('SMB.payment.contentRequestWithdrawalSuccess') }}
      </p>
    </div>

    <!-- modal footer -->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        class="btn-close"
        variant="secondary"
        pill
        @click="hideModal"
      >
        {{ $t('SMB.payment.textClose') }}
      </b-button>
      <btn-loading
        v-if="!showSuccessView"
        class="ml-1"
        variant-convert="btn-submit"
        type="submit"
        pill
        :loading="loading"
        @click.prevent="submitForm"
      >
        {{ $t('SMB.payment.textRequest') }}
      </btn-loading>
    </div>
    <!-- modal footer -->
  </b-modal>
</template>
<script>
/* eslint-disable no-underscore-dangle */

import {
  BModal,
  BRow,
  BCol,
  BButton,
  // BSpinner,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BForm,
  BImg,
  VBTooltip,
  // BInputGroupAppend, BCardTitle,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { createNamespacedHelpers } from 'vuex'
// import _get from 'lodash/get'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  rangeRefundLocalBank,
  required,
} from '@validations'
import { toastification } from '@core/mixins/toast'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import vSelect from 'vue-select'
import { TICKET_TYPE, LIST_LOCAL_BANK } from '@/constants'
import paymentMixin from '@/mixins/paymentMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
// const { mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BImg,
    vSelect,
    BModal,
    BRow,
    BCol,
    BButton,
    // BSpinner,
    // component
    BtnLoading,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    // BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [numberFormatMixin, toastification, generalConfigsMixin, billingMixin, envMixin, stepGuideMixin, paymentMixin],

  data() {
    return {
      amount: null,
      bankType: null,
      bankAccountName: '',
      bankAccountNumber: '',
      showSuccessView: false,

      // validate
      rangeRefundLocalBank,
      required,
    }
  },
  computed: {
    ...mapGetters(['loading', 'message', 'status']),

    localBanks() {
      return LIST_LOCAL_BANK
    },
  },

  methods: {
    ...mapActions(['createRecharge']),
    //  ...mapActionsAuth(['getAccountInfo']),

    resetModal() {
      this.amount = null
      this.bankType = null
      this.bankAccountName = ''
      this.bankAccountNumber = ''
      this.showSuccessView = false
    },

    hideModal() {
      this.resetModal()
      this.$refs['refund-modal'].hide()
    },

    showModal() {
      this.$refs['refund-modal'].show()
    },

    handleChangeBank(value) {
      this.bankData = value
    },

    handleSearchLocalBanks(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase().indexOf(searchTxt) > -1
        || option?.shortName?.toString().toLocaleLowerCase().indexOf(searchTxt) > -1
        || option?.code?.toString().toLocaleLowerCase().indexOf(searchTxt) > -1
    },

    async submitForm() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        const params = {
          type: TICKET_TYPE.REFUND,
          details: {
            bankType: `${this.bankData.name} (${this.bankData?.shortName}, ${this.bankData?.code})`,
            bankAccountName: this.bankAccountName,
            bankAccountNumber: this.bankAccountNumber,
            amount: this.amount,
          },
        }
        await this.createRecharge(params)
        if (this.status) {
          this.showSuccessView = true
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.modal-w-refund {
  //max-width: 556px;
  //margin-top: 55px;
}

.modal-w-withdraw {
  max-width: 734px;
  margin-top: 55px;
}

#refund-modal {
  .modal-footer-custom {
    border-top: none !important;
  }

  .modal-body {
    @media (min-width: 767px) {
      padding: 2rem !important;
    }
  }
}

.list-bank-select-refund {
  .vs__dropdown-toggle {
    align-items: start;
    padding: 6px 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-info {
  margin-left: 5px;
}

.top-modal {
  margin-bottom: 30px;
}

.content-top {
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);
  margin-bottom: 24px;
}

.input-height {
  height: 48px;
}

.text-secondary {
  color: #3d405b !important;
}

.mx-div {
  margin-top: 12px;
  margin-bottom: 12px;
}

.bank-logo{
  width: 60px;
  margin-right: 6px;
}

.btn-close {
  color: rgb(22 33 62 / 76%) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  padding: 11px 20px;
  height: max-content;
  border: 0;
}
</style>
