<template>
  <div class="d-flex align-items-center justify-content-center flex-column box">
    <b-img
      fluid
      alt="No account"
      class="img"
      :src="img"
    />
    <h3 class="mt-1">
      <slot name="title" />
    </h3>
    <p>
      <slot name="description" />
    </p>
    <slot name="action" />
  </div>
</template>

<script>
import {
  BImg,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  components: {
    BImg,
  },

  props: {
    img: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
  .box{
    max-width: 450px;
    margin: auto;
  }
  .img {
    max-width: 218px;
    max-height: 160px;
  }

  .new-acc-btn {
    margin-top: 10px;
  }
</style>
