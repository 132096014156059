<template>
  <div
    class="warning mb-2"
    @click="goToDetailsDocs"
  >
    <slot name="title" />
    <slot name="content" />
  </div>
</template>

<script>
export default {
  props: {
    linkDetail: {
      type: String,
      default: '',
    },
  },

  methods: {
    goToDetailsDocs() {
      if (this.linkDetail) {
        window.open(this.linkDetail)
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.warning {
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 15px 12px 15px 16px;
}
</style>
