import { render, staticRenderFns } from "./BalanceAllowcation.vue?vue&type=template&id=39349beb&scoped=true&"
import script from "./BalanceAllowcation.vue?vue&type=script&lang=js&"
export * from "./BalanceAllowcation.vue?vue&type=script&lang=js&"
import style0 from "./BalanceAllowcation.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./BalanceAllowcation.vue?vue&type=style&index=1&id=39349beb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39349beb",
  null
  
)

export default component.exports