<template>
  <b-modal
    ref="modal-submit-request-refund"
    id="modal-confirm-request"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between flex-column top-modal">
      <h3 class="title-popup">
        {{ $t('payment.autoRefundFeature.titleRefundRequestForm') }}
      </h3>
      <!-- <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div> -->
      <p class="description-form-request-refund">
        {{ $t('payment.autoRefundFeature.txtHereIsASummary') }} <span class="text-notice">{{ $t('payment.autoRefundFeature.txtWeWillProcess') }}</span> {{ $t('payment.autoRefundFeature.txtItWillThenTake') }} <span class="text-notice">{{ $t('payment.autoRefundFeature.txtForTheRefund') }}</span>
      </p>
    </div>

    <!-- body content -->
    <div class="body-content-modal">
      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.txtRequestedAmount') }}
        </p>
        <p class="content-info">
          ${{ requestedAmount }}
        </p>
      </div>

      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.txtRefundReason') }}
        </p>
        <p class="content-info">
          {{ refundReason }}
        </p>
      </div>

      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.nameDetailReason') }}
        </p>
        <p class="content-description">
          {{ detailReason }}
        </p>
      </div>

      <div class="info-request">
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.txtReceivingMethod') }}
        </p>
        <p class="content-info">
          {{ receivingMethod }}
        </p>
      </div>

      <div
        v-if="mailReceivingMethod"
        class="info-request"
      >
        <p class="title-info">
          {{ receivingMethod }} email
        </p>
        <p class="content-info">
          {{ mailReceivingMethod }}
        </p>
      </div>

      <div
        v-if="walletAddress"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.USDT.labelWalletAddress', { value: 'USDT' }) }}
        </p>
        <p class="content-info">
          {{ walletAddress }}
        </p>
      </div>

      <div
        v-if="bankNameLocalBank"
        class="info-request"
      >
        <p class="title-info">
          Bank
        </p>
        <p class="content-info">
          {{ bankNameLocalBank }}
        </p>
      </div>

      <div
        v-if="customerNameLocalBank"
        class="info-request"
      >
        <p class="title-info">
          Account holder name
        </p>
        <p class="content-info">
          {{ customerNameLocalBank }}
        </p>
      </div>

      <div
        v-if="accountNumberLocalBank"
        class="info-request"
      >
        <p class="title-info">
          Account number
        </p>
        <p class="content-info">
          {{ accountNumberLocalBank }}
        </p>
      </div>

      <div
        v-if="QRCode"
        class="info-request"
      >
        <p class="title-info">
          QR Code Image
        </p>

        <div
          class="drop-zoon__preview"
          @mouseover="showIconPlay = true"
          @mouseleave="showIconPlay = false"
        >
          <img
            id="previewImage"
            :src="QRCode"
            alt="Preview Image"
            class="drop-zoon__preview-image"
          >

          <div
            class="icon-play-video"
            :class="showIconPlay ? 'show-icon' : 'hide-icon'"
            @click="showModalVideo(QRCode)"
          >
            <feather-icon
              class="icon-play"
              icon="EyeIcon"
              size="32"
            />
          </div>
        </div>
      </div>

      <div
        v-if="usdtNetwork"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.autoRefundFeature.labelUSDTNetwork') }}
        </p>
        <p class="content-info">
          {{ usdtNetwork }}
        </p>
      </div>

      <div
        v-if="wiseAccountHolder"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.titleAccountHolder', { paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseAccountHolder }}
        </p>
      </div>

      <div
        v-if="wiseRountingNumber"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.titleRoutingNumber', { paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseRountingNumber }}
        </p>
      </div>

      <div
        v-if="wiseAccountNumber"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.textBankAccountNumber', {paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseAccountNumber }}
        </p>
      </div>

      <div
        v-if="wiseAccountType"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.titleAccountType', {paymentMethod: 'Wise'}) }}
        </p>
        <p class="content-info">
          {{ wiseAccountType }}
        </p>
      </div>

      <div
        v-if="wiseAddress"
        class="info-request"
      >
        <p class="title-info">
          {{ $t('payment.wiseTransfer.textAddress',{ paymentMethod: 'Wise' }) }}
        </p>
        <p class="content-info">
          {{ wiseAddress }}
        </p>
      </div>

    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <btn-loading
        variant-convert="btn-submit-outline"
        :disabled="loading"
        pill
        @click="hideModal"
      >
        {{ $t('autoTopUp.setupAutomation.edit') }}
      </btn-loading>

      <btn-loading
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant-convert="btn-submit"
        class="ml-1 btn-submit-request"
        :loading="loading"
        @click="handleSubmitRequest"
      >
        {{ $t('forgotPW.btnSendLinkReset') }}
      </btn-loading>
    </div>
    <!--/modal footer-->

    <show-screenshot-modal
      ref="open-screenshot-modal"
      :img="imgPreviewScreenshot"
    />
  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */

import {
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { toastification } from '@core/mixins/toast'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { TRANSACTION_TYPE } from '@/constants'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import ShowScreenshotModal from '@/components/ShowScreenshotModal.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BModal,
    BtnLoading,
    ShowScreenshotModal,
  },

  directives: {
    Ripple,
  },

  mixins: [toastification, stepGuideMixin],

  props: {
    infoRequestRefund: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      showIconPlay: false,
      imgPreviewScreenshot: '',
    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
    ...mapGettersAuth(['user']),

    requestedAmount() {
      return this.infoRequestRefund?.amount
    },

    refundReason() {
      switch (this.infoRequestRefund?.reasonType) {
        case 'permanent_suspended':
          return this.$t('payment.autoRefundFeature.txtPermanentServiceDiscontinuation')
        case 'temporary_suspended':
          return this.$t('payment.autoRefundFeature.txtTemporarilyStopUsingService')
        case 'other':
          return this.$t('payment.autoRefundFeature.txtOther')
        default:
          return this.infoRequestRefund?.reasonType
      }
    },

    receivingMethod() {
      switch (this.infoRequestRefund?.paymentMethod) {
        case 'paypal':
          return 'PayPal'
        case 'creditCard':
          return 'Credit card'
        case 'payoneer':
          return 'Payoneer'
        case 'airWallex':
          return 'Airwallex'
        case 'transferWise':
          return 'Wise'
        case 'tazapay':
          return 'Tazapay'
        case 'localBank':
          return 'Local bank'
        default:
          return this.infoRequestRefund?.paymentMethod
      }
    },

    mailReceivingMethod() {
      return this.infoRequestRefund?.additionalInfo?.email
    },

    walletAddress() {
      return this.infoRequestRefund?.additionalInfo?.usdtWalletAddress
    },

    usdtNetwork() {
      return this.infoRequestRefund?.additionalInfo?.usdtNetwork
    },

    wiseAccountHolder() {
      return this.infoRequestRefund?.additionalInfo?.wiseAccountHolder
    },

    wiseRountingNumber() {
      return this.infoRequestRefund?.additionalInfo?.wiseRountingNumber
    },

    wiseAccountNumber() {
      return this.infoRequestRefund?.additionalInfo?.wiseAccountNumber
    },

    wiseAccountType() {
      return this.infoRequestRefund?.additionalInfo?.wiseAccountType
    },

    wiseAddress() {
      return this.infoRequestRefund?.additionalInfo?.wiseAddress
    },

    detailReason() {
      return this.infoRequestRefund?.description
    },

    QRCode() {
      return this.infoRequestRefund?.additionalInfo?.usdtQRCodeUrl || this.infoRequestRefund?.additionalInfo?.localbankQRCodeUrl
    },

    bankNameLocalBank() {
      return this.infoRequestRefund?.additionalInfo?.bankName
    },

    customerNameLocalBank() {
      return this.infoRequestRefund?.additionalInfo?.customerName
    },

    accountNumberLocalBank() {
      return this.infoRequestRefund?.additionalInfo?.accountNumber
    },
  },

  methods: {
    ...mapActions(['requestAutoRefund', 'getHistory']),
    ...mapActionsAuth(['getAccountInfo']),

    hideModal() {
      this.$refs['modal-submit-request-refund'].hide()
    },

    showModal() {
      this.$refs['modal-submit-request-refund'].show()
    },

    showModalVideo(file) {
      this.imgPreviewScreenshot = file
      this.$refs['open-screenshot-modal'].showModal()
    },

    async handleSubmitRequest() {
      await this.requestAutoRefund(this.infoRequestRefund)

      if (this.status) {
        const params = {
          page: 1,
          size: 10,
          types: [TRANSACTION_TYPE.REFUND_USER_BALANCE],
        }
        await this.getHistory({
          type: 'refund_user_balance',
          params,
        })
        await this.getAccountInfo()
        this.toastSuccess(this.$t('payment.autoRefundFeature.requestRefundSuccess'))
        this.$router.push(this.hasStepGuide ? '/payment/add-fund?tabActive=refund_history' : '/payment?tab=refund_history')
        this.hideModal()
        this.$emit('submitRequestRefundSuccess')
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss">
  #modal-confirm-request {

    .modal-body {
      padding: 40px;
    }
  }
</style>

<style lang="scss" scoped>
  .top-modal {
    margin-bottom: 10px;
  }

  .title-popup {
      font-size: 24px;
      margin-bottom: 4px;
    }

  .description-form-request-refund {
    color: rgba(0, 0, 0, 0.70);
    font-size: 14px;
    margin: 0;
    border-radius: 10px;
    background:  rgba(38, 103, 255, 0.10);
    padding: 6px;

    .text-notice {
      color:  #000;
      font-weight: bold;
    }
  }

  .body-content-modal {
    border-radius: 20px;
    background:  rgba(0, 0, 0, 0.05);
    padding: 16px;

    .info-request {
      margin-bottom: 12px;
      word-wrap: break-word;

      .title-info {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
      }

      .content-info {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .content-description {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
  }

  .btn-submit-request {
    z-index: 999;
  }

  .drop-zoon__preview {
  position: relative;
  margin-bottom: 16px;
  width: max-content;

  .drop-zoon__preview-image {
    width: max-content;
    max-width: 200px;
    height: 110px;
    object-fit: contain;
    border-radius: 6px;
  }

  .icon-play-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;

    .icon-play {
      z-index: 999;
      color: #fff;
    }

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }

    @keyframes showicon {
      0% {
        visibility: hidden;
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
