<template>
  <b-modal
    :visible="visible"
    size="connect-tt"
    body-class="connect-tt-success-modal"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    centered
  >
    <div class="text-center">
      <TimeLine :step-active="2" />

      <p class="font-weight-bold font-24 mb-2">
        {{ $t('payment.pancake.congrats') }}
      </p>
      <b-img
        :width="207"
        :height="147"
        :src="require('@/assets/images/pages/payment/lg-ecomdy-planet.png')"
        class="mb-2"
        alt="Ecomdy logo"
      />
      <p class="font-14 mb-2 grey-content">
        {{ $t('payment.pancake.pleaseConsider') }}<span class="bold-content">{{ $t('payment.pancake.addingMoneyToYourEcomdyAccount') }}</span>{{ $t('payment.pancake.thenYouCanAllocateItToTheAdsAccountInTheNextStep') }}
        <a
          href="https://docs.ecomdymedia.com/category/step-3-add-fund"
          target="_blank"
          style="color: #2667FF"
        >{{ $t('payment.pancake.here') }}</a>{{ $t('payment.pancake.pleaseNote') }}<span class="bold-content">{{ $t('payment.pancake.readyToRunTikTokAds') }}</span>{{ $t('payment.pancake.justComeBackHere') }}
        <a
          href="https://tiktok.ecomdymedia.com"
          target="_blank"
          style="color: #2667FF"
        >https://tiktok.ecomdymedia.com</a>{{ $t('payment.pancake.loginPrompt') }}
      </p>

      <div class="wrapper-btn">
        <btn-loading
          class="btn-submit-outline font-14 custom-btn"
          variant-convert="btn-submit-outline"
          pill
          @click="handleNotThisTime"
        >
          {{ $t('payment.pancake.notThisTime') }}
        </btn-loading>

        <btn-loading
          class="custom-btn"
          variant-convert="btn-submit"
          pill
          @click="handleAddFund"
        >
          {{ $t('payment.pancake.addBalance') }}
        </btn-loading>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BImg, BModal } from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import TimeLine from '@/components/TimeLine.vue'
import authMixin from '@/mixins/authMixin'

export default {
  name: 'ConnectTikTokSuccessModal',
  components: {
    BtnLoading, BImg, BModal, TimeLine,
  },
  mixins: [authMixin],
  data() {
    return {
      visible: false,
    }
  },
  created() {
    this.initStep() // for new user from Pancake
  },
  methods: {
    closeModal() {
      this.visible = false
    },

    handleNotThisTime() {
      localStorage.removeItem('pancakeUserStep')
      this.closeModal()
      this.navigateToPancake()
    },

    handleAddFund() {
      this.$router.push({
        path: '/payment/add-fund',
        query: {
          tab: 'paypal',
        },
      })
      localStorage.setItem('pancakeUserStep', '3')
      this.closeModal()
    },

    initStep() {
      if (this.$route.query?.userPancakeConnectedTT === 'success') {
        this.visible = true
        this.$router.replace({ query: {} })
        localStorage.setItem('pancakeUserStep', '2')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper-btn {
  display: flex;
  justify-content: space-evenly;

  .custom-btn {
    width: 180px;
    @media (max-width: 576px) {
      width: 130px;
    }
  }
}

.grey-content {
  color: rgb(0, 0, 0, 0.7);
}

.bold-content {
  color: #000;
  text-shadow: 0.1px 0.1px;
}
</style>

<style lang="scss">
.modal-content {
    .connect-tt-success-modal {
      padding: 40px;
      @media (max-width: 576px) {
        padding: 20px;
        width: 100%;
      }
    }
}

.modal .modal-connect-tt {
  max-width: 620px;
  width: 620px;

  @media (max-width: 767px) {
    max-width: 95%;
    width: 100%;
  }
}
</style>
