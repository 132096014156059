<template>
  <b-modal
    v-model="showModal"
    id="modal-kickstart"
    class="modal-kickstart"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
  >
    <feather-icon
      class="ml-1 cursor-pointer icon-close"
      icon="XIcon"
      size="16"
      @click="$emit('close-modal')"
    />
    <div
      class="img-kickstart cursor-pointer"
      @click="goTo(link)"
    >
      <b-img
        :src="img"
        alt="kickstart"
        class="img-kickstart"
      />
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable camelcase */
import {
  BModal,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BImg,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    img: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: this.show,
    }
  },
  watch: {
    show: {
      handler(val) {
        this.showModal = val
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    goTo(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-hide{
  position: absolute;
  top: -12px;
  right: -12px;
}
.img-kickstart {
  width: 100%;
  border-radius: 16px;
}

.icon-close {
  color: white;
  position: absolute;
  top: 12px;
  right: 20px;
}
</style>
<style lang="scss">
#modal-kickstart {
  .modal-body {
    padding: 0px !important;
  }
}
</style>
