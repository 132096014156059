<template>
  <div class="upload-file-container">
    <!-- Drop Zoon -->
    <div
      id="dropZoon"
      class="upload-area__drop-zoon drop-zoon"
      :class="dragover ? 'drop-zoon--over' : null"
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
    >
      <div
        v-if="loadingFile"
        class="spinner-upload-img"
      >
        <b-spinner
          small
          type="grow"
        />
        <span>Loading...</span>
      </div>
      <div
        v-else-if="fileUrl"
        class="drop-zoon__preview"
        @mouseover="showIconPlay = true"
        @mouseleave="showIconPlay = false"
      >
        <img
          id="previewImage"
          :src="fileUrl"
          alt="Preview Image"
          class="drop-zoon__preview-image"
        >

        <div
          class="drop-zoon__remove-image"
          :class="showIconPlay ? 'show-icon' : 'hide-icon'"
          @click="handleRemoveImage"
        >
          <feather-icon
            class="icon-play"
            icon="XCircleIcon"
            size="20"
          />
        </div>

        <div
          class="icon-play-video"
          :class="showIconPlay ? 'show-icon' : 'hide-icon'"
          @click="showModalVideo(fileUrl)"
        >
          <feather-icon
            class="icon-play"
            icon="EyeIcon"
            size="32"
          />
        </div>
      </div>
      <div
        v-else
        class="drop-zoon__content"
      >
        <feather-icon
          class="icon-play"
          icon="UploadCloudIcon"
          size="40"
        />

        <p class="drop-zoon__paragraph">
          {{ $t("productLinkPage.textClickOnTheBtn") }}
        </p>
      </div>
      <btn-loading
        variant-convert="btn-submit-outline"
        pill
        class="d-flex align-items-center"
        @click="handleUploadImg"
      >
        {{
          fileUrl
            ? 'Change Image'
            : 'Upload'
        }}
      </btn-loading>
      <div class="mt-1">
        <p class="note-input">
          <span class="text-danger">*</span> {{ $t('payment.autoRefundFeature.txtSupportedFile') }}
        </p>
        <p class="note-input">
          {{ $t('payment.autoRefundFeature.txtMaximumSize') }}
        </p>
      </div>
      <input
        ref="refVideo"
        id="fileInput"
        type="file"
        class="drop-zoon__file-input"
        accept=".jpg, .png, .jpeg"
        @change="onFileChange"
      >
    </div>
    <!-- End Drop Zoon -->

    <!-- Error upload product video -->
    <div class="error-upload-video">
      <p
        v-if="errorVideoProduct && !loadingFile"
        class="text-error"
      >
        {{ errorVideoProduct }}
      </p>
    </div>
    <!-- End error upload product video -->

    <show-screenshot-modal
      ref="open-screenshot-modal"
      :img="imgPreviewScreenshot"
    />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import store from '@/store'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import ShowScreenshotModal from './ShowScreenshotModal.vue'

const { mapActions: mapActionsFile } = createNamespacedHelpers('file')
export default {
  components: {
    BtnLoading,
    ShowScreenshotModal,
    BSpinner,
  },

  mixins: [toastification],

  data() {
    return {
      dragover: false,
      imageFile: null,
      nameVideoUploaded: '',
      errorVideoProduct: '',
      showIconPlay: false,
      imgPreviewScreenshot: '',
    }
  },

  setup() {
    const refImage = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refImage, () => {})

    return {
      refImage,
      inputImageRenderer,
    }
  },

  computed: {
    ...mapGetters({
      statusFile: 'file/status',
      fileUrl: 'file/fileUrl',
      loadingFile: 'file/loading',
      messageFile: 'file/message',
    }),
  },

  watch: {
    imageFile(val) {
      if (val) {
        this.upload(val)
      }
    },
  },

  methods: {
    ...mapActionsFile(['uploadFile']),

    handleRemoveImage() {
      store.state.file.fileUrl = ''
      this.showIconPlay = false
    },

    showModalVideo(file) {
      this.imgPreviewScreenshot = file
      this.$refs['open-screenshot-modal'].showModal()
    },

    handleUploadImg() {
      if (!this.loadingFile) {
        this.$refs.refVideo.click()
      }
    },

    onFileChange(evt) {
      this.imageFile = evt.dataTransfer?.files[0] || this.$refs.refVideo?.files[0]
    },

    onDrop(evt) {
      this.$refs.refVideo.files = evt.dataTransfer.files
      this.dragover = false
      this.onFileChange(evt)
    },

    /**
     * Function to handle file upload.
     *
     * @param {File} file - The file to be uploaded.
     * @returns {void}
     */
    async upload(file) {
      if (file) {
        // Get the file from the reference
        const refFile = this.$refs.refVideo.files[0]

        // Set the name of the uploaded video
        this.nameVideoUploaded = this.imageFile?.name

        // Check if the file size exceeds 100MB
        if (refFile.size > 1024 * 1024 * 100) {
          // Show a toast message if the file size is too large
          this.toastFailure(this.$t('productLinkPage.toastVideoSizeLess'))
        } else {
          // Upload the file using the uploadFile action
          await this.uploadFile(file)

          // Check the status of the file upload
          if (!this.statusFile) {
            // Show a toast message if the file upload fails
            this.toastFailure(this.messageFile)
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
.progress-upload-bar {
  .progress-upload-video {
    height: 10px;
    &.progress-bar-success {
      margin-top: 10px;
      background-color: rgba(110, 68, 255, 0.12);

      .bg-success {
        background: linear-gradient(128deg, #81fff4 -29.89%, #6e44ff 98.77%);
      }
    }
  }
}
</style>

<style lang='scss' scoped>
/* Drop Zoon */
.upload-area__drop-zoon {
  padding: 32px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: #f5f5f7;
  border-radius: 15px;
  transition: border-color 300ms ease-in-out;

  border: 1px dashed #fff;
  background: linear-gradient(90deg, white, white) padding-box,
    linear-gradient(128deg, #81FFF4 -29.89%, #6E44FF 98.77%) border-box;
}

.drop-zoon__content {
  text-align: center;
}

.title-input-upload-file {
  margin: 12px 0;
  font-size: 24px;
  font-weight: 400;
  transition: opacity 300ms ease-in-out;
}

.drop-zoon__paragraph {
  font-size: 12px;
  color: #000000;
  transition: opacity 300ms ease-in-out;
  font-style: italic;
}

.note-input {
  text-align: center;
  margin: 0;
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
}

.drop-zoon__preview {
  position: relative;
  margin-bottom: 16px;

  .drop-zoon__preview-image {
    width: max-content;
    max-width: 200px;
    height: 110px;
    object-fit: contain;
    border-radius: 6px;
  }

  .drop-zoon__remove-image {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #4d4d6c;
    border-radius: 50%;
    z-index: 9999;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }
  }

  .icon-play-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;

    .icon-play {
      z-index: 999;
      color: #fff;
    }

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }

    @keyframes showicon {
      0% {
        visibility: hidden;
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.drop-zoon__file-input {
  display: none;
}

/* (drop-zoon--over) Modifier Class */
// .drop-zoon--over {
//   border-color: rgba(0, 0, 0, 0.7);
// }

.drop-zoon--over .title-input-upload-file,
.drop-zoon--over .drop-zoon__paragraph {
  opacity: 0.7;
}

/* (drop-zoon--over) Modifier Class */
// .drop-zoon--Uploaded {

// }

.drop-zoon--Uploaded .drop-zoon__paragraph {
  display: none;
}

/* File Details Area */
.upload-area__file-details {
  height: 0;
  visibility: hidden;
  opacity: 0;
  text-align: left;
  transition: none 500ms ease-in-out;
  transition-property: opacity, visibility;
  transition-delay: 500ms;
}

/* (duploaded-file--open) Modifier Class */
.file-details--open {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.file-details__title {
  font-size: 1.125rem;
  font-weight: 500;
  color: rgb(196, 195, 196);
}

.progress-upload-bar {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
}

.progress-name-video {
  margin: 0;
  color: #16213e;
  font-size: 24px;
  width: 650px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-upload {
  margin-right: 6px;
}

.spinner-upload-img {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
</style>
