<template>
  <b-modal
    ref="modal-warning-amount-ads-account"
    id="modal-confirm-request"
    hide-footer
    hide-header
    centered
  >

    <!-- body content -->
    <div class="body-content-modal">
      <div class="icon-container">
        <b-img
          :src="require('@/assets/images/pages/payment/img-warning-amount-ads-account.png')"
        />
      </div>

      <div class="content-request-success">
        <h4 class="title-content-request">
          {{ $t('payment.autoRefundFeature.titleWarningAmountAdsAccount') }}
        </h4>
        <p class="content-request">
          {{ $t('payment.autoRefundFeature.subWarningAmountAdsAccount') }}
        </p>
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-center">
      <btn-loading
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant-convert="btn-submit-outline"
        pill
        class="btn-width"
        @click="handleActionSkip"
      >
        {{ $t('payment.autoRefundFeature.btnSkip') }}
      </btn-loading>

      <btn-loading
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant-convert="btn-submit"
        class="btn-width ml-1"
        pill
        @click="handleActionWithdrawImmediately"
      >
        {{ $t('payment.autoRefundFeature.btnGoToWithdrawMoney') }}
      </btn-loading>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */

import {
  BModal,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BModal,
    BImg,
    BtnLoading,
  },

  directives: {
    Ripple,
  },

  methods: {
    hideModalWarningAmount() {
      this.$refs['modal-warning-amount-ads-account'].hide()
    },

    showModalWarningAmount() {
      this.$refs['modal-warning-amount-ads-account'].show()
    },

    handleActionWithdrawImmediately() {
      this.hideModalWarningAmount()
      this.$router.push('/payment?tab=balance_allocation')
      this.$emit('onHidden')
    },

    handleActionSkip() {
      this.hideModalWarningAmount()
      this.$emit('submitInfoRequestPayment')
    },
  },
}
</script>

<style lang="scss">
  #modal-confirm-request {

    .modal-body {
      padding: 40px;
    }
  }
</style>

<style lang="scss" scoped>
  .top-modal {
    margin-bottom: 32px;
  }

  .body-content-modal {

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }

    .content-request-success {
      text-align: center;
      margin-bottom: 24px;

      .title-content-request {
        font-size: 20px;
        margin-bottom: 6px;
      }

      .content-request {
        font-size: 14px;
        margin: 0;
      }
    }

    .info-request {
      margin-bottom: 12px;

      .title-info {
        font-size: 12px;
        font-weight: 500;
        margin: 0;
      }

      .content-info {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .btn-width {
    width: 180.33px;
  }
</style>
