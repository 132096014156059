<template>
  <div class="d-flex align-items-center justify-content-center flex-column box">
    <b-img
      fluid
      alt="No account"
      class="img"
      :src="require('@/assets/images/common/no-data-table.svg')"
    />
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>

<style lang="scss" scoped>
  .box{
    max-width: 450px;
    margin: auto;
  }
  .img {
    max-width: 218px;
    max-height: 160px;
  }

  .new-acc-btn {
    margin-top: 10px;
  }
</style>
