<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { ADS_ACCOUNT_STATUS, DATE_RANGE } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')
export default {
  mixins: [numberFormatMixin],

  data() {
    return {
      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // date picker
      rangeDate: null,
      configDate: {
        mode: 'range',
      },

      // status filter
      statusSelected: {
        name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
        value: '',
      },
      statusOptions: [
        {
          name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
          value: '',
        },
        {
          name: this.$t('myAdAccountsPage.textApproved'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED,
        },
        {
          name: this.$t('myAdAccountsPage.textNotApproved'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED,
        },
        {
          name: this.$t('myAdAccountsPage.textPunishment'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED,
        },
        {
          name: this.$t('myAdAccountsPage.textReview'),
          value: ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW,
        },
        {
          name: this.$t('myAdAccountsPage.textNotYetEffective'),
          value: ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE,
        },
        {
          name: this.$t('myAdAccountsPage.textClosed'),
          value: ADS_ACCOUNT_STATUS.CLOSED,
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
      openAddFundModal: false,
    }
  },

  computed: {

    ...mapGetters(['listAccount', 'message', 'status', 'loading']),

    accounts() {
      return get(this.listAccount, ['content'], [])
    },

    totalAccount() {
      return get(this.listAccount, ['paging', 'total'], 0)
    },

    adsStatusVariant() {
      const statusColor = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: 'light-success',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: 'light-warning',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: 'light-danger',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: 'light-info',
      }

      return status => statusColor[status]
    },

    adsStatusTxt() {
      const statusColor = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: this.$t('myAdAccountsPage.textNotApproved'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: this.$t('myAdAccountsPage.textPunishment'),
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: this.$t('myAdAccountsPage.textReview'),
        [ADS_ACCOUNT_STATUS.CLOSED]: this.$t('payment.statusClosed'),
        [ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE]: this.$t('myAdAccountsPage.textNotYetEffective'),
      }

      return status => statusColor[status]
    },

    alertTextColor() {
      const txtColor = {
        [true]: 'text-danger',
        [false]: 'text-success',
      }

      return txt => txtColor[txt]
    },

    adsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ads-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ads-acc.svg')
    },

    baAdsAccountImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/common/ic-ba-acc-emedia.svg')
      }
      return require('@/assets/images/common/ic-ba-acc.svg')
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    rangeDates() {
      return {
        from: this.rangeDate.startDate,
        to: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },
  },

  methods: {
    ...mapActions(['getAccountList']),
    async fetchAdsAccounts() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
        ...(this.searchTerm && { keyword: this.searchTerm }),
        ...((this.rangeDate.startDate || this.rangeDate.endDate) && this.rangeDates),
        ...(this.statusSelected.value && { status: this.statusSelected.value }),
      }
      await this.getAccountList(params)
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 2) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchAdsAccounts()
        }, 600)
      }
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchAdsAccounts()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchAdsAccounts()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchAdsAccounts()
    },

    async handleDateChange(selectedDates) {
      this.rangeDate = selectedDates
      this.fetchAdsAccounts()
    },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchAdsAccounts()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchAdsAccounts()
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .ads-avt-img {
    width: 44px;
    height: 44px;
    margin-right: 10px;
  }
  .close-icon {
    .input-group-text {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .badge{
    border-radius: 100px;
    padding: 6px 12px;
    font-weight: 600;
  }
</style>
