<template>
  <div>
    <b-modal
      ref="modal-download"
      centered
      hide-footer
      size="w-custom"
    >
      <div class="invoice-body">
        <div class="header d-flex justify-content-between">
          <div class="left d-flex">
            <img
              :src="logo"
              alt="logo"
            >
            <div class="d-flex flex-column">
              <h4
                class="text-uppercase font-medium font-20"
                style="margin-bottom: 3px"
              >
                {{ companyName }}
              </h4>
              <p class="font-12">
                <strong>Business Number</strong> {{ businessNumber }}
              </p>
              <p class="font-12">
                {{ companyAddress }}
              </p>
              <p class="font-12">
                {{ companyCountry }}
              </p>
              <p class="font-12">
                {{ companyTax }}
              </p>
              <p class="font-12">
                {{ companyPhone }}
              </p>
              <p class="font-12">
                {{ companyEmail }}
              </p>
            </div>
          </div>
          <div class="right d-flex flex-column text-right">
            <div class="item">
              <p class="text-uppercase font-medium">
                Invoice
              </p>
              <span>
                {{ getInvoiceId }}
              </span>
            </div>
            <div class="item">
              <p class="text-uppercase font-medium">
                Date
              </p>
              <span>
                {{ getDate }}
              </span>
            </div>
            <div class="item">
              <p class="text-uppercase font-medium">
                Due
              </p>
              <span>
                On Receipt
              </span>
            </div>
            <div class="item">
              <p class="text-uppercase font-medium">
                balance due
              </p>
              <span>
                USD ${{ localStringFormat(totalAmount) }}
              </span>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="body">
          <validation-observer
            ref="formValidation"
            v-slot="{ invalid }"
          >
            <b-form class="mt-2">
              <h6 class="mb-0 font-medium">
                BILL TO
              </h6>

              <!-- company name -->
              <b-form-group class="my-2">
                <b-row>
                  <!-- Company Name -->
                  <b-col
                    sm="12"
                    md="12"
                    lg="6"
                  >
                    <b-form-group
                      class="m-0"
                    >
                      <div class="d-flex justify-content-between">
                        <label
                          class="form-label"
                        >
                          Company name
                        </label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        id="companyName"
                        name="Company name"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            v-model.trim="clientInfo.companyName"
                            class="form-control-merge input-height"
                            name="companyName"
                            placeholder="Enter your company name"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Email -->
                  <b-col
                    sm="12"
                    md="12"
                    lg="6"
                  >
                    <b-form-group
                      class="m-0"
                      label-for="email"
                    >
                      <div class="d-flex justify-content-between">
                        <label
                          for="email"
                          class="form-label"
                        >
                          {{ $t('common.labelEmail') }}
                        </label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            v-model.trim="clientInfo.email"
                            id="email"
                            name="email"
                            class="input-height"
                            :placeholder="$t('common.placeholderEmail')"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>

              <!-- phone input -->
              <b-row>
                <b-col
                  cols="12"
                >
                  <phone-input :phone-data.sync="phoneData" />
                </b-col>
              </b-row>

              <!-- address -->
              <b-form-group class="my-2">
                <div class="d-flex justify-content-between">
                  <label
                    class="form-label"
                  >
                    Address
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="address"
                  name="address"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="clientInfo.address"
                      name="address"
                      class="input-height"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter your Address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Country -->
              <b-row>
                <!-- country -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <b-form-group class="m-0">
                      <div class="d-flex justify-content-between">
                        <label
                          class="form-label"
                        >
                          Country
                        </label>
                      </div>
                      <div class="custom-date-input">
                        <v-select
                          v-model="clientInfo.countrySelected"
                          label="name"
                          style="width: 100%"
                          placeholder="Select your country"
                          class="country-select"
                          :clearable="false"
                          :options="listCountry"
                        >
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <feather-icon
                                icon="ChevronDownIcon"
                                size="20"
                              />
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </div>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- zip code -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group class="m-0">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                      >
                        Zip code
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      id="zipCode"
                      name="Zip code"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          v-model.trim="clientInfo.zipCode"
                          class="input-height"
                          placeholder="Enter your Zip code"
                          :state="errors.length > 0 ? false:null"
                        />
                      </b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group class="mt-2 mb-3">
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                      >
                        VAT number (Optional)
                      </label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="vat number"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model.trim="clientInfo.vatNumber"
                          id="vat-number"
                          name="vat number"
                          class="input-height"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter your vat number"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-form-group>

              <div class="summary">
                <hr class="divider-custom">
                <b-row class="header">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    Description
                  </b-col>
                  <b-col
                    cols="2"
                    class="font-medium"
                  >
                    Rate
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-center font-medium"
                  >
                    qty
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-right font-medium"
                  >
                    amount
                  </b-col>
                </b-row>
                <hr class="divider-custom">

                <b-row class="body">
                  <b-col cols="6">
                    <span>Top up TikTok Agency Ad Account</span>
                    <span v-if="checkHasPoweredByEcomdy">
                      <br> (Powered by Ecomdy Media)
                    </span>
                  </b-col>
                  <b-col
                    cols="2"
                  >
                    ${{ localStringFormat(subTotal) }}
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-center"
                  >
                    1
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-right"
                  >
                    ${{ localStringFormat(subTotal) }}
                  </b-col>
                  <b-col cols="12">
                    <hr class="mb-0">
                  </b-col>
                </b-row>

                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    subtotal
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(subTotal) }}
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5 my-1">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    service charge
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(serviceCharge) }}
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    tax
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(tax) }}
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    Total
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(totalAmount) }}
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium d-flex align-items-center"
                  >
                    balance due
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right font-20 font-medium"
                  >
                    USD ${{ localStringFormat(totalAmount) }}
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-row class="bottom offset-5 mt-1">
                <b-col
                  cols="12"
                  md="5"
                >
                  <b-button
                    variant="outline-secondary"
                    pill
                    @click="closeModal"
                  >
                    Close
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  md="7"
                >
                  <btn-loading
                    variant-convert="primary"
                    block
                    type="submit"
                    pill
                    :loading="false"
                    :disabled="validData(invalid)"
                    @click.prevent="validationForm"
                  >
                    Download Invoice
                  </btn-loading>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </div>

    </b-modal>
    <div>
      <vue-html2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        filename="invoice"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        @progress="onProgress($event)"
        @startPagination="startDownload()"
      >
        <section
          slot="pdf-content"
          class="m-3"
        >
          <div class="invoice-body invoice-body-pdf">
            <div class="header d-flex justify-content-between">
              <div class="left d-flex">
                <img
                  :src="require('@/assets/images/logo/pati-logo.png')"
                  alt="logo"
                >
                <div class="d-flex flex-column">
                  <h4
                    class="text-uppercase font-medium font-20"
                    style="margin-bottom: 3px"
                  >
                    {{ companyName }}
                  </h4>
                  <p class="font-12">
                    <strong>Business Number</strong> {{ businessNumber }}
                  </p>
                  <p class="font-12">
                    {{ companyAddress }}
                  </p>
                  <p class="font-12">
                    {{ companyCountry }}
                  </p>
                  <p class="font-12">
                    {{ companyTax }}
                  </p>
                  <p class="font-12">
                    {{ companyPhone }}
                  </p>
                  <p class="font-12">
                    {{ companyEmail }}
                  </p>
                </div>
              </div>
              <div class="right d-flex flex-column text-right">
                <div class="item">
                  <p class="text-uppercase font-medium">
                    Invoice
                  </p>
                  <span>
                    {{ getInvoiceId }}
                  </span>
                </div>
                <div class="item">
                  <p class="text-uppercase font-medium">
                    Date
                  </p>
                  <span>
                    {{ getDate }}
                  </span>
                </div>
                <div class="item">
                  <p class="text-uppercase font-medium">
                    Due
                  </p>
                  <span>
                    On Receipt
                  </span>
                </div>
                <div class="item">
                  <p class="text-uppercase font-medium">
                    balance due
                  </p>
                  <span>
                    USD ${{ localStringFormat(totalAmount) }}
                  </span>
                </div>
              </div>
            </div>
            <hr class="my-1">
            <div class="body my-1">
              <h6 class="font-medium">
                BILL TO
              </h6>
              <div class="text-left mt-1 mb-2">
                <h4>
                  {{ clientInfo.companyName }}
                </h4>
                <p
                  v-if="clientInfo.countrySelected"
                  style="margin-bottom: 6px"
                >
                  {{ `${clientInfo.countrySelected.name}, ${clientInfo.address}` }}
                </p>
                <p style="margin-bottom: 6px">
                  Zipcode: {{ clientInfo.zipCode }}
                </p>
                <p
                  v-if="clientInfo.vatNumber"
                  style="margin-bottom: 6px"
                >
                  VAT Number: {{ clientInfo.vatNumber }}
                </p>
                <p style="margin-bottom: 6px">
                  {{ `(+${phoneData.countryCode})${phoneData.phone}` }}
                </p>
                <p style="margin-bottom: 6px">
                  {{ clientInfo.email }}
                </p>
              </div>
              <div class="summary">
                <hr class="divider-custom">
                <b-row class="header">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    Description
                  </b-col>
                  <b-col
                    cols="2"
                    class="font-medium"
                  >
                    Rate
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-center font-medium"
                  >
                    qty
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-right font-medium"
                  >
                    amount
                  </b-col>
                </b-row>
                <hr class="divider-custom">

                <b-row class="body">
                  <b-col cols="6">
                    <span>Top up TikTok Agency Ad Account</span>
                    <span v-if="checkHasPoweredByEcomdy">
                      <br> (Powered by Ecomdy Media)
                    </span>
                  </b-col>
                  <b-col
                    cols="2"
                  >
                    ${{ localStringFormat(subTotal) }}
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-center"
                  >
                    1
                  </b-col>
                  <b-col
                    cols="2"
                    class="text-right"
                  >
                    ${{ localStringFormat(subTotal) }}
                  </b-col>
                  <b-col cols="12">
                    <hr class="mb-0">
                  </b-col>
                </b-row>

                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    subtotal
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(subTotal) }}
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5 my-1">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    service charge
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(serviceCharge) }}
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    tax
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(tax) }}
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium"
                  >
                    Total
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    ${{ localStringFormat(totalAmount) }}
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row class="bottom offset-5">
                  <b-col
                    cols="6"
                    class="font-medium d-flex align-items-center"
                  >
                    balance due
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right font-20 font-medium"
                  >
                    USD ${{ localStringFormat(totalAmount) }}
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'

import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BButton,
  // BCardTitle, BCardText, BLink,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'

// libs & cons
// import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import VueHtml2pdf from 'vue-html2pdf'
import PhoneInput from '@/views/auth/PhoneInput.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { COUNTRIES, PAYMENT_METHOD } from '@/constants'
import envMixin from '@/mixins/envMixin'

// mixins
// component

const { mapGetters } = createNamespacedHelpers('payment')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BtnLoading,
    PhoneInput,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueHtml2pdf,
  },
  mixins: [toastification, numberFormatMixin, envMixin],
  props: {
    invoiceData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      clientInfo: {
        companyName: '',
        countrySelected: null,
        userEmail: '',
        vatNumber: '',
        address: '',
        zipCode: '',
      },
      phoneData: {
        countryAbbr: 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
      },
      invoice: null,
      listCountry: COUNTRIES,
      loadingDownload: false,
      // validations
      required,
      email,
    }
  },

  computed: {

    ...mapGetters(['listBillingHistory', 'message', 'status', 'loading']),

    histories() {
      return this.listBillingHistory.content
    },

    serviceCharge() {
      return this.invoice?.payload?.serviceCharge || 0
    },

    tax() {
      return this.invoice?.payload?.taxCharge || 0
    },

    totalAmount() {
      return this.invoice?.payload?.bankTransferAmount || this.subTotal
    },

    subTotal() {
      return this.invoice?.amount || 0
    },

    getInvoiceId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.invoice?.paymentId || this.invoice?._id || 'N/A'
    },

    getDate() {
      return new Date(this.invoice?.createdAt).toDateString() || 'N/A'
    },

    validData() {
      return invalid => invalid || this.loadingDownload || this.phoneData.phone === '' || (this.phoneData.phone !== '' && !this.phoneData.isValid)
    },

    logo() {
      // eslint-disable-next-line global-require
      return process.env.VUE_APP_REGULAR_PLAN_LOGO || require('@/assets/images/logo/logo.svg')
    },

    companyName() {
      return process.env.VUE_APP_COMPANY_NAME
    },

    businessNumber() {
      return process.env.VUE_APP_INVOICE_BUSINESS_NUMBER
    },

    companyAddress() {
      return process.env.VUE_APP_INVOICE_ADDRESS
    },

    companyCountry() {
      return process.env.VUE_APP_INVOICE_COUNTRY
    },

    companyTax() {
      return process.env.VUE_APP_INVOICE_TAX
    },

    companyPhone() {
      return process.env.VUE_APP_INVOICE_NUMBER_PHONE
    },

    companyEmail() {
      return process.env.VUE_APP_INVOICE_EMAIL
    },

    checkHasPoweredByEcomdy() {
      const method = this.invoice?.paymentMethod
      return this.isPATIPlatform && (method === PAYMENT_METHOD.TRANSFERWISE || method === PAYMENT_METHOD.USDT || method === PAYMENT_METHOD.AIRWALLEX)
    },
  },

  watch: {
    invoiceData: {
      handler(val) {
        this.invoice = val
      },
      deep: true,
    },
  },

  methods: {

    openModal() {
      this.$refs['modal-download'].show()
    },

    closeModal() {
      this.$refs['modal-download'].hide()
    },

    onProgress(e) {
      if (e === 100) {
        this.loadingDownload = false
        this.$refs['modal-download'].hide()
      }
    },

    startDownload() {
      this.loadingDownload = true
    },

    async validationForm() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        this.$refs.html2Pdf.generatePdf()
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  .invoice-body {
    padding: 8px 24px;

    .left {
      img {
        width: 115px;
        height: 115px;
        margin-right: 24px;
      }

      p {
        margin-bottom: -1px;
      }
    }

    .right {
      margin-bottom: 2px;

      .item {
        margin-bottom: 4px;
        p {
          font-size: 12px;
          margin-bottom: -8px;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .summary {

      .header {
        padding: 12px 0;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }

      .body, .bottom {
        padding: 16px 0;
        font-size: 14px;
      }

      .bottom {
        text-transform: uppercase;
        padding-bottom: 0;
        padding-top: 0;

        hr{
          margin-top: .8rem;
          margin-bottom: .8rem;
        }
      }

      .divider-custom {
        margin: 0;
        border-color: #16213E;
      }
    }
  }

  .invoice-body-pdf {
    padding-top: 30px !important;
  }
</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .country-select {
    input {
      font-size: 1rem;
    }
  }

  .modal-w-custom {
    max-width: 748px;
  }

  .custom-date-input .vs__dropdown-toggle {
    padding: 8px 0 7px 0 !important;
  }
</style>
