<template>
  <div>
    <div v-if="isExclusivePartner">
      <warning-notification
        class="notification-refund-policy"
        :link-detail="linkDetail"
      >
        <template #title>
          <div class="title-notification">
            <div class="ic-docs">
              <feather-icon
                icon="FileTextIcon"
                size="24"
              />
            </div>
            <h3 class="m-0">
              [AdReach] Refund Policy
            </h3>
            <div class="ic-notification">
              <feather-icon
                icon="ArrowUpRightIcon"
                size="21"
              />
            </div>
          </div>
        </template>

        <template #content>
          <div>
            <p class="m-0">
              Please review our refund policy carefully to understand your rights and obligations when requesting a refund.
            </p>
          </div>
        </template>
      </warning-notification>
    </div>

    <banner-header
      v-if="isExclusivePartner"
      :img="require('@/assets/images/banner/banner-popup-adreach.png')"
    >
      <template #content>
        <div
          class="btn-join-partnero"
        >
          <div class="content-background">
            <h1 class="title-ecomdy-affiliate">
              <span class="text-opacity">Easy and automatic</span> Top-up
            </h1>
            <h1 class="title-ecomdy-affiliate">
              in Pakistan Rupees <span class="text-opacity">now with Payfast!</span>
            </h1>
          </div>
          <div class="btn-controller">
            <div class="btn-learn-more">
              <a
                href="https://ecomdycom.larksuite.com/docx/MeOvdQghRo48tYxmCT3ulHOysmh"
                target="_blank"
              >
                {{ $t('referral.btnLearnMore') }}
              </a>
            </div>
          </div>
        </div>
      </template>
    </banner-header>

    <div
      class="payment"
    >
      <div
        data-testid="balance-information-container"
        class="content-balance mb-2"
      >
        <!--Balance overview-->
        <balance-overview />
        <!--/ Balance overview-->
      </div>

      <div
        data-testid="tabs-history-container"
        class="tabs-history"
      >
        <!--History-->
        <tabs-history />
        <!--/ History-->
      </div>
    </div>

    <banner-kickstart-modal
      :show="showModalKickstart"
      :img="require('@/assets/images/pages/payment/banner-top-up-payfast.png')"
      link="https://ecomdycom.larksuite.com/docx/MeOvdQghRo48tYxmCT3ulHOysmh"
      @close-modal="onCloseModalKickstart"
    />

    <ConnectTikTokSuccessModal v-if="isPancakeUser" />
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'
import BannerHeader from '@/views/home/components/BannerHeader.vue'
import BannerKickstartModal from '@/views/home/components/BannerKickstartModal.vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import ConnectTikTokSuccessModal from '@/views/payment/components/ConnectTikTokSuccessModal.vue'
import authMixin from '@/mixins/authMixin'
import BalanceOverview from './components/BalanceOverview.vue'
import TabsHistory from './components/TabsHistory/TabsHistory.vue'
import WarningNotification from '../home/components/WarningNotification.vue'

const { mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersPayment, mapActions } = createNamespacedHelpers('payment')

export default {
  components: {
    ConnectTikTokSuccessModal,
    TabsHistory,
    BalanceOverview,
    // components
    BannerHeader,
    BannerKickstartModal,
    WarningNotification,
  },
  mixins: [envMixin, stepGuideMixin, authMixin],

  data() {
    return {
      // showBalanceAllowCation: false,
      showModalKickstart: false,
      linkDetail: 'https://ecomdycom.larksuite.com/docx/NA9LdCbBNo2IQFxXCkluRequsGe',
    }
  },

  computed: {
    ...mapGetters(['status', 'listBusinessAccountHistoryLimit', 'loading']),
    ...mapGettersAuth(['user']),
    ...mapGettersPayment(['listUsedPaymentMethods']),
  },

  watch: {
    //   $route: {
    //     immediate: true,
    //     handler(val) {
    //       if (val.query?.type === 'show_balance') {
    //         this.showBalance()
    //       }
    //     },

    user: {
      handler() {
        if (localStorage.getItem('show-banner-modal') !== 'hide' && !this.hasStepGuide && this.isExclusivePartner) {
          this.showModalKickstart = true
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.fetchListUsedPaymentMethods()
  },

  methods: {
    ...mapActions(['getListUsedPaymentMethods']),

    // showBalance() {
    //   this.showBalanceAllowCation = true
    // },

    onCloseModalKickstart() {
      this.showModalKickstart = false
      localStorage.setItem('show-banner-modal', 'hide')
    },

    async fetchListUsedPaymentMethods() {
      await this.getListUsedPaymentMethods()
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-refund-policy {
    cursor: pointer;
    background: rgba(38, 103, 255, 0.1) !important;

    .title-notification {
      margin-bottom: .5rem;

      display: flex;
      align-items: center;

      .ic-docs {
        margin-right: 5px;
      }

      .ic-notification {
        margin-left: 6px;
        color: #2667ff;
      }
    }
  }
</style>
