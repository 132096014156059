<template>
  <b-modal
    ref="no-money-balance-modal"
    id="no-money-balance-modal"
    no-close-on-backdrop
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-end top-modal">
      <feather-icon
        data-testid="icon-close"
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hideModal"
      />
    </div>

    <div class="mt-2">
      <div class="d-flex justify-content-center">
        <b-img
          rounded
          class="ml-1"
          :src="isFromTikTok ? require('@/assets/images/pages/payment/bg-no-balance-tiktok-app-center.png') : require('@/assets/images/pages/payment/bg-no-money.png')"
          style="width: 180px"
        />
      </div>
      <div
        data-testid="modal-title-need-add-balance"
        class="text-center"
      >
        <h3>{{ $t('autoTopUp.noMoneyBalance.needAddBalance') }}</h3>
        <p
          data-testid="content-add-balance"
          class="mb-0"
        >
          {{ $t('autoTopUp.noMoneyBalance.contentAdBalance', {platformName: platformName}) }}
        </p>
        <btn-loading
          data-testid="btn-add-balance"
          class="mt-3"
          type="submit"
          variant-convert="btn-submit"
          pill
          :loading="false"
          @click.prevent="handelAddBalance"
        >
          <span>{{ $t('autoTopUp.noMoneyBalance.addBalance') }}</span>
        </btn-loading>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BImg } from 'bootstrap-vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BImg,
    BtnLoading,
  },

  mixins: [envMixin],

  computed: {
    ...mapGettersAuth(['isTikTokAppCenter']),
  },

  methods: {
    hideModal() {
      this.$refs['no-money-balance-modal'].hide()
      this.$emit('cancelModal', false)
    },

    showModal() {
      this.$refs['no-money-balance-modal'].show()
    },

    handelAddBalance() {
      if (this.isTikTokAppCenter) {
        const idToken = localStorage.getItem('id_token')
        if (this.isOnlyEcomdyPlatform) {
          window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?tab=paypal&id_token=${idToken}`)
        } else {
          window.open(`${process.env.VUE_APP_SITE_URL}/payment/add-fund?id_token=${idToken}`)
        }
      } else if (this.isOnlyEcomdyPlatform) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
      this.hideModal()
    },
  },
}
</script>
