export default {
  methods: {
    validateHttps(value) {
      if (value.length === 0) return null
      return value.length > 8 && value.includes('https://', 0) && null
    },

    validateText(str) {
      let result = str
      if (str.indexOf('https://') !== 0) {
        result = `https://${result}`
      }
      return result
    },

    onlyNumber(e) {
      if (e.key.length === 1 && /\D/.test(e.key)) {
        e.preventDefault()
      }
    },

    numberDecimal(e) {
      const regex = /^(\d)*(\.)?([0-9]{1})?$/
      if (e.keyCode !== 8) {
        if (!regex.test(e.key)) {
          e.preventDefault()
        }
      }
    },
  },
}
